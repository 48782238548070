h1 {
    margin-left: 15px;
  }
  
  h2 {
    margin-left: 30px;
  }
  
  h3 {
    margin-left: 45px;
  }

  h4 {
    margin-left: 45px;
  }
  
  label {
    /*margin-left: 60px;*/
  }
  
