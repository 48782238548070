chtml,
body {
	font-family: 'Sniglet';
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

.gridItemNumber{
    grid-column: 1;
    grid-row: 1;
}

.gridItemName{
    grid-column: 2 / span 4;
    grid-row: 1;
}

.gridItemComment{
    grid-column: 1 / span 5;
    grid-row: 2;
}