.brainimage:before {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;
    width: 1200px;
    height: 800px;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
      }

      .brainimage:before {
        content: '';
        background-color: rgb(255, 255, 255);
        opacity: 0.75;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }

      .content {
        position: fixed;
        left: 0;
        right: 0;
        opacity: 100;
        z-index: 9999;
        margin-left: 20px;
        margin-right: 20px;
        -webkit-animation: slide 2s forwards;
        -webkit-animation-delay: 1s;
        animation: slide 2s forwards;
        animation-delay: 1s;
        height: 100vh;
        overflow-y: scroll;
      }

      /* @-webkit-keyframes slide {
        0% {
          opacity: 0;
          transform: translateX(-100%);
        }
        1% {
          opacity: 1;
          
        }
        35% {
          opacity: 1;
          transform: translateX(10%);
        }
        65% {
          opacity: 1;
          transform: translateX(-5%);
        }
        75% {
          opacity: 1;
          transform: translateX(3%);
        }
        85% {
          opacity: 1;
          transform: translateX(-2%);
        }
        90% {
          opacity: 1;
          transform: translateX(1%);
        }
        95% {
          opacity: 1;
          transform: translateX(-1%);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
    } */

    /* @keyframes slide {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      1% {
        opacity: 1;
        
      }
      35% {
        opacity: 1;
        transform: translateX(10%);
      }
      65% {
        opacity: 1;
        transform: translateX(-5%);
      }
      75% {
        opacity: 1;
        transform: translateX(3%);
      }
      85% {
        opacity: 1;
        transform: translateX(-2%);
      }
      90% {
        opacity: 1;
        transform: translateX(1%);
      }
      95% {
        opacity: 1;
        transform: translateX(-1%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
  } */
